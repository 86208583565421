import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {connect, useDispatch} from 'react-redux';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import {
    getApiRoot,
    getContextRoot,
    getLanguageValue,
    navigateToPath,
    resetRetrieveIndividualData,
    googleRecaptcha,
    resetRegisterIndividualStagesData,
    isVisaReady,
    isInactiveField, splitArrayIntoChunks
} from "../../common/functions";
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import lodashGet from 'lodash/get';
import lodashSet from 'lodash/set';
import { CSS_COLORS } from '../../common/cssColors';
import CONSTANTS from '../../common/constants';
import moment from 'moment';
import { ACTIONS } from '../../redux/actions';
import EditIcon from '@material-ui/icons/Edit';
import SearchIcon from '@material-ui/icons/Search';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import CancelIcon from '@material-ui/icons/Cancel';
import Loader from "../Loader";
import SyncIcon from '@material-ui/icons/Sync';

function RegisterGroupStage2Component(props) {
    const dispatch = useDispatch();
    const contextRoot = getContextRoot();
    const {
        actions
    } = props;

    const [ mobileTableData, setMobileTableData ] = useState([]);

    let [ errors, setErrors ] = useState({});
    const [ isLoading, setIsLoading ] = useState(false);
    const [ loaders, setLoaders ] = useState({ });
    let [ fieldErrors, setFieldErrors ] = useState({});
    const [ disclaimerChecked, setDisclaimerChecked ] = useState(false);

    useEffect(() => {
        actions.setActiveStage(props.isPaid ? 3 : 2);
    }, [props.isPaid]);

    let fieldsList = [
        { key: 'groupKey', description: getLanguageValue('register-group.stage2-group-id'), render: function(value) { return value; } },
        { key: 'stage1Data.firstName', description: getLanguageValue('register-group.stage2-first-name'), render: function(value) { return value; } },
        { key: 'stage1Data.lastName', description: getLanguageValue('register-group.stage2-family-name'), render: function(value) { return value; } },
        { key: 'stage1Data.organization', description: getLanguageValue('register-group.stage2-organization'), render: function(value) { return value; } },
        { key: 'stage1Data.birthDate', description: getLanguageValue('register-group.stage2-date-of-birth'), render: function(value) { return moment(lodashGet(props, 'stage1Data.birthDate')).format(CONSTANTS.DATE_FORMAT_MOMENT); } },
        { key: 'stage1Data.email', description: getLanguageValue('register-group.stage2-email'), render: function(value) { return value; } },
    ];
    let fieldsChunks = splitArrayIntoChunks(fieldsList, 3);

    useEffect(() => {
        // refresh group data (in case coming from individual form or from successful group payment page)
        if (props.refreshGroupData) {
            dispatch({
                type: ACTIONS.GENERIC_SET_VALUE,
                payload: [{
                    path: 'globalControls.refreshGroupData',
                    value: false
                }]
            });
            setLoaders({ ...loaders, refresh: true });
            setIsLoading(true);
            (async function() {
                // refresh linkedApplicationsData
                let recaptchaToken = await googleRecaptcha();
                let url = getApiRoot() + 'retrieve-information/?type=group&languageKey=' + props.languageKey + '&td=' + (new Date().getTime() - window.routeChangeTimestamp);
                let options = {
                    ...CONSTANTS.POST_DEFAULT_OPTIONS,
                    body: JSON.stringify({
                        recaptchaToken,
                        key: props.groupKey,
                        email: lodashGet(props.stage1Data, 'email'),
                        firstName: lodashGet(props.stage1Data, 'firstName'),
                        lastName: lodashGet(props.stage1Data, 'lastName'),
                        birthDate:  lodashGet(props.stage1Data, 'birthDate')
                    })
                };
                try {
                    let result = await (await fetch(url, options)).json();
                    if (lodashGet(result, 'data.success')) {
                        // good
                        let stage1Data = { ...lodashGet(result, 'data.groupApplication') };
                        stage1Data = {
                            firstName: stage1Data.firstName,
                            lastName: stage1Data.lastName,
                            organization: stage1Data.organization,
                            birthDate: stage1Data.birthDate,
                            email: stage1Data.email,
                            emailConfirm: stage1Data.email
                        };
                        let stage2Data = { linkedIndividualApplications: lodashGet(result, 'data.linkedIndividualApplications') };
                        dispatch({
                            type: ACTIONS.GENERIC_SET_VALUE,
                            payload: [{
                                path: 'registerGroupStagesData',
                                value: {
                                    isPaid: lodashGet(result, 'data.groupApplication.isPaid'),
                                    createDate: lodashGet(result, 'data.groupApplication.createDate'),
                                    key: lodashGet(result, 'data.groupApplication.key'),
                                    status: lodashGet(result, 'data.groupApplication.status'),
                                    stage1Data,
                                    stage2Data,
                                }
                            }]
                        });
                        setIsLoading(false);
                        setLoaders({ ...loaders, refresh: false });
                        return;
                    }
                    else {
                        setErrors({ ...errors, refresh: getLanguageValue('register-group.stage2-failed-to-refresh') });
                    }
                }
                catch (err) {
                    setErrors({ ...errors, refresh: getLanguageValue('register-group.stage2-failed-to-refresh') });
                }
                setIsLoading(false);
                setLoaders({ ...loaders, refresh: false });
            })();
        }
        window.routeChangeTimestamp = new Date().getTime();
    }, []);

    useEffect(() => {
        let newOutput = [];
        for (let loop = 0 ; loop < props.linkedApplicationsData.length ; ++loop) {
            const item = props.linkedApplicationsData[loop];
            let rejectWithReasonsFlag = false;
            const itemRejectReasons = item.rejectReasons;
            if (itemRejectReasons && Object.keys(itemRejectReasons).length) {
                rejectWithReasonsFlag = true;
            }
            newOutput.push({
                cols: [
                    { name: '#', value: (loop + 1) },
                    { name: getLanguageValue('register-group.stage2-table-header-name'), value: item.name },
                    { name: getLanguageValue('register-group.stage2-table-header-date-of-birth'), value: moment(item.birthDate).format(CONSTANTS.DATE_FORMAT_MOMENT) },
                    { name: getLanguageValue('register-group.stage2-table-header-application-number'), value: item.key },
                    { name: getLanguageValue('register-group.stage2-table-header-passport-number'), value: item.passportNumber },
                    { name: getLanguageValue('register-group.stage2-table-header-status'), value: <span className={"form-status " + item.status}>{getLanguageValue('register-application-status-' + item.status + (rejectWithReasonsFlag ? '-with-reasons' : ''))}</span> }
                ],
                item
            });
        }
        setMobileTableData(newOutput);
    }, [ props.linkedApplicationsData ]);

    return <RegisterGroupStage2Style>
        <div className="form-container">
            <h1>{getLanguageValue('register-group.stage2-group-information')}</h1>
            {loaders.refresh ? <div className="loading-message">{getLanguageValue('register-common.loading')}</div> : null}
            {!loaders.refresh && <>
            <div className="desktop-details-table">
                {fieldsChunks.map((chunkArray, chunkArrayIndex) => {
                    return <React.Fragment key={chunkArrayIndex}><Grid className={"group-user-information-grid-row" + (chunkArray.length == chunkArrayIndex + 1 ? ' last' : '')} justify={'space-between'} container>{chunkArray.map((fieldItem, fieldItemIndex) => {
                        return <Grid key={chunkArrayIndex + '_' + fieldItemIndex} item data-key={fieldItem.key} className={"cell-container" + (fieldItemIndex == 0 ? ' first' : '')} md={4}>
                            <span className={"cell-title"}>{fieldItem.description}</span>
                            <br />
                            <span className={"cell-value"}>{fieldItem.render(lodashGet(props, `${fieldItem.key}`))}</span>
                        </Grid>;
                    })}</Grid><hr className={"default-form-hr-style"} /></React.Fragment>;
                })}
            </div>
            <h2>{getLanguageValue('register-group.stage2-group-applications')}</h2>

            {!props.isPaid ? <>
                <div>{getLanguageValue('register-group.stage2-group-applications-information')}</div>
                <div className="bold-text field-warning-color">{getLanguageValue('register-group.stage2-required-fields-with-asterisk')}</div>
                <div className={"add-application-buttons-container"}>
                    <Button className="common-style-primary-button add-new-application-button" onClick={addNewApplication}>{getLanguageValue('register-group.stage2-add-new-application')}</Button>
                    <br className={"mobile-line-separator"} />
                    <br className={"mobile-line-separator"} />
                    <a className="add-unpaid-application-href" onClick={(e) => { e.preventDefault(); addUnpaidApplication(); }} href={"#"}>{getLanguageValue('register-group.stage2-add-unpaid-application')}</a>
                </div></> : null}
            <div className={"total-applications-text"}>{getLanguageValue('register-group.stage2-total-applications')} &nbsp; {getTotalApplications()}</div>
            <div className={"table-container"}>
                <table className="desktop-forms-table">
                    <thead>
                    <tr>
                        <th>#</th>
                        <th>{getLanguageValue('register-group.stage2-table-header-name')}</th>
                        <th>{getLanguageValue('register-group.stage2-table-header-date-of-birth')}</th>
                        <th>{getLanguageValue('register-group.stage2-table-header-application-number')}</th>
                        <th>{getLanguageValue('register-group.stage2-table-header-passport-number')}</th>
                        <th>{getLanguageValue('register-group.stage2-table-header-status')}</th>
                        <th>&nbsp;</th>
                        <th>&nbsp;</th>
                    </tr>
                    </thead>
                    <tbody>
                    {props.linkedApplicationsData.map(function(item, itemIndex) {
                        return <tr className={'table-row' + (itemIndex % 2 == 0 ? ' even' : ' odd')} key={itemIndex}>
                            <td>{itemIndex+1}</td>
                            <td>{item.name}</td>
                            <td>{moment(item.birthDate).format(CONSTANTS.DATE_FORMAT_MOMENT)}</td>
                            <td>{item.key}</td>
                            <td>{item.passportNumber}</td>
                            <td><div className="status-desktop-container">{getStatus(item, 'desktop')}</div></td>
                            <td>
                                {!props.isPaid && <><Button className="update-button" onClick={() => { updateApplication(item); }}><EditIcon className="update-icon" />{getLanguageValue('register-group.stage2-table-action-update')} {lodashGet(loaders, item.key + '-update') ? <Loader /> : null}</Button>{lodashGet(errors, item.key + '-update') ? <span className="error-message field-warning-color">{getLanguageValue('register-network-error')}</span> : null}<br/><br/></>}
                                <Button className="view-button" onClick={() => { viewApplication(item); }}><SearchIcon className="view-icon" />{getLanguageValue('register-group.stage2-table-action-view')} {lodashGet(loaders, item.key + '-view') ? <Loader /> : null}</Button>{lodashGet(errors, item.key + '-view') ? <span className="error-message field-warning-color">{getLanguageValue('register-network-error')}</span> : null}
                                {item.rejectReasons && Object.keys(item.rejectReasons).length > 0 && <><br/><br/><Button className="update-rejected-data-button" onClick={() => { updateRejectedData(item); }}><SyncIcon className="update-rejected-data-icon" />{getLanguageValue('register-group.stage2-table-action-update-rejected-data')} {lodashGet(loaders, item.key + '-update-rejected-data') ? <Loader /> : null}</Button>{lodashGet(errors, item.key + '-update-rejected-data') ? <span className="error-message field-warning-color">{getLanguageValue('register-network-error')}</span> : null}</>}
                            </td>
                            <td>
                                {props.isPaid && isVisaReady(item.status) && <div className={"download-visa-container"}><div className={"download-visa-files-title-text"}>{getLanguageValue('register-group.stage2-table-action-download')}:</div> {getVisaLinks(item)}</div>}
                            </td>
                        </tr>;
                    })}
                    </tbody>
                </table>
            </div>
            <div className="mobile-forms-table">
                {mobileTableData.map(function(rowItem, rowItemIndex) {
                    return <React.Fragment key={rowItemIndex}>
                            {rowItem.cols.map(function(colItem, colItemIndex) {
                                return <div key={colItemIndex} className={"row-item " + (rowItemIndex % 2 == 0 ? ' even' : ' odd')}>
                                    <div className="row-name-container">{colItem.name}</div>
                                    <div className="row-value-container">{colItem.value}</div>
                                </div>;
                            })}
                            <div className="application-controls-buttons-container">
                                {!props.isPaid && <div className="update-button-container"><Button className="update-button" onClick={() => { updateApplication(rowItem.item); }}><EditIcon className="update-icon" />{getLanguageValue('register-group.stage2-table-action-update')} {lodashGet(loaders, rowItem.item.key + '-update') ? <Loader /> : null}</Button>{lodashGet(errors, rowItem.item.key + '-update') ? <span className="error-message field-warning-color">{getLanguageValue('register-network-error')}</span> : null}</div>}
                                <div className={"buttons-spacer"} />
                                <div className={"view-button-container" + (props.isPaid ? ' max-width' : '')}><Button className="view-button" onClick={() => { viewApplication(rowItem.item); }}><SearchIcon className="view-icon" />{getLanguageValue('register-group.stage2-table-action-view')} {lodashGet(loaders, rowItem.item.key + '-view') ? <Loader /> : null}</Button>{lodashGet(errors, rowItem.item.key + '-view') ? <span className="error-message field-warning-color">{getLanguageValue('register-network-error')}</span> : null}</div>
                                {rowItem.item.rejectReasons && Object.keys(rowItem.item.rejectReasons).length > 0 && <div className={"update-rejected-data-container max-width"}><Button className="update-rejected-data-button" onClick={() => { updateRejectedData(rowItem.item); }}><SyncIcon className="update-rejected-data-icon" />{getLanguageValue('register-group.stage2-table-action-update-rejected-data')} {lodashGet(loaders, rowItem.item.key + '-update-rejected-data') ? <Loader /> : null}</Button>{lodashGet(errors, rowItem.item.key + '-update-rejected-data') ? <span className="error-message field-warning-color">{getLanguageValue('register-network-error')}</span> : null}</div>}
                            </div>
                            {props.isPaid && isVisaReady(rowItem.item.status) && <><div className={"download-visa-container"}><div className={"download-visa-files-title-text"}>{getLanguageValue('register-group.stage2-table-action-download')}</div> {getVisaLinks(rowItem.item)}</div><br/></>}
                        </React.Fragment>;
                })}
            </div>
            {!props.isPaid ? <div className="ready-to-pay-and-payment-summary-container">
                <div className="ready-to-pay-container">
                    <h2>{getLanguageValue('register-individual.stage7-not-ready-to-pay-title')}</h2>
                    <div dangerouslySetInnerHTML={{ __html: getLanguageValue('register-individual.stage7-not-ready-to-pay-explanation') }} />
                    <br />
                    <div><span className="payment-due-by-title bold-text">{getLanguageValue('register-individual.stage7-payment-due-by')} </span> <span className="payment-due-by-date bold-text field-warning-color">{moment(props.createDate).add(7, 'days').format(CONSTANTS.DATE_FORMAT_MOMENT)}</span></div>
                    <div dangerouslySetInnerHTML={{ __html: getLanguageValue('register-individual.stage7-payment-due-by-note') }} />
                </div>
                <div className="payment-summary-container">
                    <h2>{getLanguageValue('register-individual.stage7-payment-summary')}</h2>
                    <div className="payment-summary-table">
                        <div className="payment-summary-row">
                            <div className="payment-summary-name product-type">
                                <div className="header">{getLanguageValue('register-individual.stage7-product-type')}</div>
                                <div>{getLanguageValue('register-individual.stage7-visa-type-30days-single')}</div>
                                <div>{getLanguageValue('register-individual.stage7-visa-type-30days-multi')}</div>
                                <div>{getLanguageValue('register-individual.stage7-visa-type-90days-single')}</div>
                                {!isInactiveField('stage2', 'visaExpedited') && <div>{getLanguageValue('register-individual.stage7-expedite-visa')}</div>}
                            </div>
                            <div className="payment-summary-type product-count">
                                <div className="header">{getLanguageValue('register-individual.stage7-product-count')}</div>
                                <div>{getVisaTypeCount('30days-single')}</div>
                                <div>{getVisaTypeCount('30days-multi')}</div>
                                <div>{getVisaTypeCount('90days-single')}</div>
                                {!isInactiveField('stage2', 'visaExpedited') && <div>{getVisaExpediteCount()}</div>}
                            </div>
                            <div className="payment-summary-value product-price">
                                <div className="header">{getLanguageValue('register-individual.stage7-product-price')}</div>
                                <div className="visa-price-container">{CONSTANTS.DOLLAR}{getPrice('visaType', '30days-single')}</div>
                                <div className="visa-price-container">{CONSTANTS.DOLLAR}{getPrice('visaType', '30days-multi')}</div>
                                <div className="visa-price-container">{CONSTANTS.DOLLAR}{getPrice('visaType', '90days-single')}</div>
                                {!isInactiveField('stage2', 'visaExpedited') && <div className="visa-price-container">{CONSTANTS.DOLLAR}{getPrice('expedite')}</div>}
                            </div>
                        </div>
                        <hr/>
                        <div className="payment-summary-row">
                            <div className="payment-summary-name">{getLanguageValue('register-individual.stage7-total-fee')}</div>
                            <div />
                            <div className="payment-summary-value total-price">{CONSTANTS.DOLLAR}{getTotalPrice()}</div>
                        </div>
                        <hr/>
                        <div className="disclaimer-container">
                            <FormControlLabel
                                className={"disclaimer-component"}
                                classes={{ label: 'disclaimer-label' + (fieldErrors.disclaimer ? ' field-warning-color' : '') }}
                                control={
                                    <Checkbox
                                        className={"disclaimer-checkbox-component" + (fieldErrors.disclaimer ? ' field-warning-color field-error' : '')}
                                        checked={disclaimerChecked}
                                        onChange={(e) => { setDisclaimerChecked(e.target.checked); setFieldErrors({ ...fieldErrors, disclaimer: false }); }}
                                        name="disclaimer"
                                        color="primary"
                                    />
                                }
                                label={getLanguageValue('register-individual.stage7-disclaimer')}
                            />
                            {fieldErrors.disclaimer ? <div className="error-message field-warning-color">{fieldErrors.disclaimer}</div> : null}
                            <div className="disclaimer-message" dangerouslySetInnerHTML={{ __html: getLanguageValue('register-individual.stage7-disclaimer-message') }} />
                        </div>
                        <div className="pay-now-button-container">
                            <Button className={"pay-now-button common-style-primary-button"} onClick={payNow}>{getLanguageValue('register-individual.stage7-pay-now-button-text')}{loaders.payNow ? <Loader /> : null}</Button>
                        </div>
                        {errors.generalError && <div className="general-error-message">
                            {errors.generalError}
                        </div>}
                    </div>
                </div>
            </div> : <br/>}
            </>}
        </div>
    </RegisterGroupStage2Style>;

    function addNewApplication() {
        resetRegisterIndividualStagesData(dispatch, props.state);
        setTimeout(() => {
            dispatch({
                type: ACTIONS.GENERIC_SET_VALUE,
                payload: [{
                    path: 'globalControls.isIndividualLinkedToGroup',
                    value: true
                }]
            });
            navigateToPath(dispatch, getContextRoot() + 'register-visa-for-individual');
        }, 10);
    }

    function addUnpaidApplication() {
        dispatch({
            type: ACTIONS.GENERIC_SET_VALUE,
            payload: [{
                path: 'globalControls.isRetrieveLinkedToGroup',
                value: true
            }]
        });
        resetRetrieveIndividualData(dispatch, props.state);
        navigateToPath(dispatch, getContextRoot() + 'retrieve-individual-application?isRetrieveLinkedToGroup=1');
    }

    function getStatus(item) {

        let fullCircleArrayCount = [];
        let emptyCircleArrayCount = [];
        let rejectWithReasonsFlag = false;
        const itemRejectReasons = item.rejectReasons;
        if (itemRejectReasons && Object.keys(itemRejectReasons).length) {
            rejectWithReasonsFlag = true;
        }
        switch (item.status) {
            case 'paid':
                fullCircleArrayCount = [1,1];
                emptyCircleArrayCount = [1,1];
                break;
            case 'waiting-for-agent':
                fullCircleArrayCount = [1,1,1];
                emptyCircleArrayCount = [1];
                break;
            case 'ready':
            case 'ready-without-files':
                fullCircleArrayCount = [1,1,1,1];
                emptyCircleArrayCount = [];
                break;
            case 'reject':
                fullCircleArrayCount = [1,1,1];
                return <>
                    <span className={"form-status " + item.status}>{getLanguageValue('register-application-status-' + item.status + (rejectWithReasonsFlag ? '-with-reasons' : ''))}</span>
                    <br/>
                    <span className={"progress-circles-container"}>
                        {fullCircleArrayCount.map((item, itemIndex) => (<FiberManualRecordIcon key={itemIndex} className={"circle-component"} />))}
                        <CancelIcon className={"reject-circle-component"} />
                    </span>
                </>;
            default:
                fullCircleArrayCount = [1];
                emptyCircleArrayCount = [1,1,1];
                break;
        }
        return <>
            <span className={"form-status " + item.status}>{getLanguageValue('register-application-status-' + item.status)}</span>
            <br/>
            <span className={"progress-circles-container"}>
                {fullCircleArrayCount.map((item, itemIndex) => (<FiberManualRecordIcon key={itemIndex} className={"circle-component"} />))}
                {emptyCircleArrayCount.map((item, itemIndex) => (<RadioButtonUncheckedIcon key={itemIndex} className={"circle-component"} />))}
            </span>
        </>;
        return null;
    }

    async function updateApplication(item) {
        // fetch application and edit it
        if (isLoading) {
            return;
        }
        setErrors({ ...errors, [item.key + '-update'] : false });
        let url = getApiRoot() + 'retrieve-information/?type=individual&languageKey=' + props.languageKey + '&td=' + (new Date().getTime() - window.routeChangeTimestamp);
        let recaptchaToken = await googleRecaptcha();
        let options = {
            ...CONSTANTS.POST_DEFAULT_OPTIONS,
            body: JSON.stringify({
                recaptchaToken,
                key: item.key,
                birthDate: item.birthDate,
                passportNumber: item.passportNumber
            })
        };
        try {
            setLoaders({...loaders, [item.key + '-update']: true });
            let result = await (await fetch(url, options)).json();
            setIsLoading(false);
            setLoaders({...loaders, [item.key + '-update']: false });
            if (!lodashGet(result, 'data.success')) {
                setErrors({ ...errors, [item.key + '-update']: true });
                return;
            }
            // update email to prevent form locking
            lodashSet(result, 'data.individualApplication.stage2Data.emailConfirm' , lodashGet(result, 'data.individualApplication.stage2Data.email'));
            // update state data
            dispatch({
                type: ACTIONS.GENERIC_SET_VALUE,
                payload: [
                    {
                        path: 'registerIndividualStagesData',
                        value: lodashGet(result, 'data.individualApplication')
                    },
                    {
                        path: 'globalControls.isIndividualLinkedToGroup',
                        value: true
                    }
                ]
            });
            navigateToPath(dispatch, getContextRoot() + 'register-visa-for-individual/stage2');
        }
        catch (err) {
            // error
            setErrors({ ...errors, [item.key + '-update'] : true });
            setIsLoading(false);
            setLoaders({...loaders, [item.key + '-update']: false });
        }
    }

    async function viewApplication(item) {
        // fetch application and view it
        if (isLoading) {
            return;
        }
        setErrors({ ...errors, [item.key + '-view'] : false });
        let url = getApiRoot() + 'retrieve-information/?type=individual&languageKey=' + props.languageKey + '&td=' + (new Date().getTime() - window.routeChangeTimestamp);
        let recaptchaToken = await googleRecaptcha();
        let options = {
            ...CONSTANTS.POST_DEFAULT_OPTIONS,
            body: JSON.stringify({
                recaptchaToken,
                key: item.key,
                birthDate: item.birthDate,
                passportNumber: item.passportNumber
            })
        };
        try {
            setLoaders({...loaders, [item.key + '-view']: true });
            let result = await (await fetch(url, options)).json();
            setIsLoading(false);
            setLoaders({...loaders, [item.key + '-view']: false });
            if (!lodashGet(result, 'data.success')) {
                setErrors({ ...errors, [item.key + '-view']: true });
                return;
            }
            // update state data
            dispatch({
                type: ACTIONS.GENERIC_SET_VALUE,
                payload: [{
                    path: 'registerIndividualStagesData',
                    value: lodashGet(result, 'data.individualApplication')
                }]
            });
            // go to view page
            navigateToPath(dispatch, getContextRoot() + 'register-visa-for-individual/view');
        }
        catch (err) {
            // error
            setErrors({ ...errors, [item.key + '-view'] : true });
            setIsLoading(false);
            setLoaders({...loaders, [item.key + '-view']: false });
        }
    }

    async function updateRejectedData(item) {
        if (isLoading) {
            return;
        }
        setErrors({ ...errors, [item.key + '-update-rejected-data'] : false });
        setLoaders({...loaders, [item.key + '-update-rejected-data']: true });
        let url = getApiRoot() + 'retrieve-information/?type=individual&languageKey=' + props.languageKey + '&td=' + (new Date().getTime() - window.routeChangeTimestamp);
        let recaptchaToken = await googleRecaptcha();
        let bodyObject = {
            passportNumber: lodashGet(item, 'passportNumber'),
            birthDate: lodashGet(item, 'birthDate'),
        };
        let options = {
            ...CONSTANTS.POST_DEFAULT_OPTIONS,
            body: JSON.stringify({
                recaptchaToken,
                key: item.key,
                ...bodyObject
            })
        };
        try {
            let result = await (await fetch(url, options)).json();
            setLoaders({...loaders, [item.key + '-update-rejected-data']: false });
            if (!lodashGet(result, 'data.success')) {
                setErrors({ ...errors, [item.key + '-update-rejected-data']: true });
                return;
            }
            // update state data
            let stateData = { };
            stateData.groupKey = props.groupKey;
            stateData.formKey = item.key;
            stateData.fields = {}; // currently nothing to fix
            stateData.files = {};
            let filesRejectReasons = [ 'passport', 'passportFace', 'birthCertificate', 'parentPassport' ];
            for (let rejectReason of filesRejectReasons) {
                if (lodashGet(item.rejectReasons, rejectReason)) {
                    let matchedFile = (lodashGet(result, 'data.individualApplication.uploadedFiles') || []).find((uploadedItem => uploadedItem.fileType == rejectReason))
                    if (matchedFile) {
                        stateData.files[rejectReason] = matchedFile;
                    }
                }
            }

            dispatch({
                type: ACTIONS.GENERIC_SET_VALUE,
                payload: [{
                    path: 'rejectedApplicationData',
                    value: stateData
                }]
            });
            // go to update rejected data page
            navigateToPath(dispatch, getContextRoot() + 'update-rejected-data');
        }
        catch (err) {
            // error
            setErrors({ ...errors, [item.key + '-update-rejected-data'] : true });
            setLoaders({...loaders, [item.key + '-update-rejected-data']: false });
        }
    }

    function getVisaLinks(item) {
        let fileIndex = 1;
        for (let loop = 0 ; loop < props.linkedApplicationsData.length ; ++loop) {
            let individualAppData = props.linkedApplicationsData[loop];
            if (individualAppData.key == item.key && individualAppData.visaFilesIdArray) {
                return individualAppData.visaFilesIdArray.map((item, itemIndex) => (<React.Fragment key={itemIndex}><a target={'_blank'} href={getContextRoot() + 'api/download-visa/?type=group&key=' + individualAppData.key + '&groupKey=' + props.groupKey + '&fileId=' + item}>{getLanguageValue('register-common.download-filename')}{fileIndex++}</a><br /></React.Fragment>));
            }
        }
        return null;
    }

    function getVisaTypeCount(visaType) {
        let count = 0;
        for (let item of props.linkedApplicationsData) {
            if (item.visaType == visaType) {
                count++;
            }
        }
        return count;
    }

    function getVisaExpediteCount() {
        let count = 0;
        for (let item of props.linkedApplicationsData) {
            if (item.visaExpedited) {
                count++;
            }
        }
        return count;
    }

    function getTotalApplications() {
        return props.linkedApplicationsData.length;
    }

    function getPrice(productType, visaType) {
        const prices = CONSTANTS.PRICES;
        switch (productType) {
            case 'visaType':
                switch (visaType) {
                    case '30days-single':
                        return getVisaTypeCount('30days-single') && prices ? prices['30days_single'] : 0;
                    case '30days-multi':
                        return getVisaTypeCount('30days-multi') && prices ? prices['30days_multi'] : 0;
                    case '90days-single':
                        return getVisaTypeCount('90days-single') && prices ? prices['90days_single'] : 0;
                    case '90days-multi':
                        return getVisaTypeCount('90days-multi') && prices ? prices['90days_multi'] : 0;
                }
                break;
            case 'expedite':
                return getVisaExpediteCount() && prices ? prices['expedite'] : 0;
        }
        return 0;
    }

    function getTotalPrice() {
        const prices = CONSTANTS.PRICES;
        return getVisaTypeCount('30days-single') * getPrice('visaType', '30days-single') +
            getVisaTypeCount('30days-multi') * getPrice('visaType', '30days-multi') +
            getVisaTypeCount('90days-single') * getPrice('visaType', '90days-single') +
            getVisaTypeCount('90days-multi') * getPrice('visaType', '90days-multi') +
            getVisaExpediteCount() * (prices ? prices['expedite'] : 0);
    }

    function isNextDisabled() {
        return false;
    }

    async function payNow() {
        // goto next stage
        setIsLoading(true);
        setLoaders({ ...loaders, payNow: true });
        const validateResult = await validate({ setErrors: true });
        setIsLoading(false);
        setLoaders({ ...loaders, payNow: false });
        if (validateResult.length) {
            setTimeout(function() {
                if (document.querySelectorAll('.field-error')[0]) {
                    document.querySelectorAll('.field-error')[0].scrollIntoView();
                }
            }, 50);
            return;
        }
        if (actions && actions.nextStage) {
            actions.nextStage();
        }
    }

    async function validate(config) {
        const localErrors = [];
        if (lodashGet(config, 'setErrors')) {
            fieldErrors = {};
        }
        if (!disclaimerChecked) {
            localErrors.push('disclaimer');
            if (lodashGet(config, 'setErrors')) {
                fieldErrors.disclaimer = getLanguageValue('register-field-errors-must-confirm');
            }
        }
        else if (!lodashGet(props, 'linkedApplicationsData.length')) {
            //register-group.stage2-error-there-are-no-forms
            localErrors.push('no-forms');
            if (lodashGet(config, 'setErrors')) {
                errors.generalError = getLanguageValue('register-group.stage2-error-there-are-no-forms');
            }
        }
        else {
            // check all applications are filled
            let recaptchaToken = await googleRecaptcha();
            let url = getApiRoot() + 'validate-group-forms/?languageKey=' + props.languageKey + '&td=' + (new Date().getTime() - window.routeChangeTimestamp);
            let options = {
                ...CONSTANTS.POST_DEFAULT_OPTIONS,
                body: JSON.stringify({
                    recaptchaToken,
                    key: props.groupKey
                })
            };
            try {
                let result = await (await fetch(url, options)).json();
                if (!lodashGet(result, 'data.success')) {
                    if (lodashGet(result, 'err') == 'no adult forms') {
                        localErrors.push('no-adult-forms');
                        errors.generalError = getLanguageValue('register-group.stage2-error-no-adult-form');
                    }
                    else {
                        localErrors.push('incomplete-forms');
                        errors.generalError = getLanguageValue('register-group.stage2-error-incomplete-form');
                    }
                }
            }
            catch (err) {
                localErrors.push('incomplete-forms');
            }
        }
        if (lodashGet(config, 'setErrors')) {
            setFieldErrors(fieldErrors);
            setErrors({ ...errors });
        }
        return localErrors;
    }
}

const RegisterGroupStage2Style = styled.div`
  //padding: 20px 27%;
  max-width: 950px;
  margin: 0 auto;
  padding-right: 151px;
  @media (max-width: 1400px) {
    padding-right: 50px;
    padding-left: 50px;
    max-width: unset;
  }
  @media (max-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE}px) {
    padding: 20px 10%;
  }
  .loading-message {
    margin-bottom: 20px;
  }
  .group-user-information-grid-row {
    @media (max-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE}px) {
      display: block;
      br, +hr {
        display: none;
      }
      .cell-value {
        html.rtl & {
          float: left;
        }
        html.ltr & {
          float: right;
        }
      }
    }
  }
  .cell-title {
    font-weight: bold;
  }
  .add-application-buttons-container {
    margin-top: 25px;
    margin-bottom: 25px;
  }
  .mobile-line-separator {
    @media (min-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE+1}px) {
      display: none;
    }
  }
  .add-new-application-button {
    html.rtl & {
      margin-left: 10px;
    }
    html.ltr & {
      margin-right: 10px;
    }
  }
  .add-unpaid-application-href {
    font-size: 19px;
  }
  .total-applications-text {
    font-size: 18px;
  }
  .update-button, .view-button, .update-rejected-data-button {
    border: 1px solid black;
    height: 35px;
    width: 100%;
    white-space: nowrap;
  }
  .download-visa-container {
    @media (min-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE+1}px) {
      min-width: 60px;
    }
  }
  .download-visa-files-title-text {
    @media (max-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE}px) {
      color: green;
    }
  }
  .desktop-forms-table {
    @media (max-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE}px) {
      display: none;
    }
    min-width: 840px;
    border-collapse: collapse; 
    border: 1px solid black;
    .table-row.even {
      background-color: #eee;
    }
    .table-row.odd {
      background-color: #ccc;
    }
    th {
      background-color: #1c3764;
      color: white;
      //border-left: 1px solid black;
      //border-bottom: 1px solid black;
      padding-top: 5px;
      padding-bottom: 5px;
      //margin: 0;
      border: 1px solid black;
    }
    td {
      text-align: center;
      padding-top: 5px;
      padding-bottom: 5px;
      padding-left: 20px;
      padding-right: 20px;
    }
  }
  .progress-circles-container {
    white-space: nowrap;
  }
  .reject-circle-component {
    color: ${CSS_COLORS.FIELD_WARNING};
  }
  .circle-component {
    color: #1460aa;
  }
  
  .mobile-forms-table {
    @media (min-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE+1}px) {
      display: none;
    }
    .row-item {
      display: flex;
      justify-content: space-between;
      &.odd {
        background-color: #ccc;
      }
      &.even {
        background-color: #eee;
      }
      .row-name-container {
        font-weight: bold;
        font-size: 16px;
      }
      .row-value-container {
        font-size: 13px;
      }
    }
    .application-controls-buttons-container {
      margin-top: 10px;
      margin-bottom: 25px;
      > div {
        display: inline-block;
        width: 48%;
        vertical-align: top;
      }
      .buttons-spacer {
        width: 4%;
      }
      .max-width {
        width: 100%;
      }
    }
    .update-rejected-data-container {
       margin-top: 10px;
    }
  }
  
  
  .form-status {
    &.unpaid, &.reject {
      font-weight: bold;
      color: ${CSS_COLORS.FIELD_WARNING};
    }
    &.ready, &.ready-without-files {
      font-weight: bold;
      color: green;
    }
  }
  
  .ready-to-pay-and-payment-summary-container {
    vertical-align: top;
    @media (min-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE+1}px) {
        width: 80%;
        min-width: 840px;
        > div {
          display: inline-block;
        }
    }
    padding-bottom: 25px;
  }
  .ready-to-pay-container {
    vertical-align: top;
    @media (min-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE+1}px) {
      width: 66%;
      padding-left: 1%;
      html.ltr & {
        width: 59%
      }
    }
  }
  .payment-summary-container {
    @media (min-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE+1}px) {
      width: 33%;
      html.ltr & {
        width: 40%;
      }
    }
  }
  .payment-due-by-title {
    color: #045575;
    font-size: 20px;
  }
  .payment-due-by-date {
    font-size: 20px;
  }
  .payment-summary-row {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    .header {
      font-weight: bold;
    }
    .visa-price-container, .total-price {
      width: 74px;
      html.ltr & {
        width: 97px;
      }
    }
  }
  .total-price {
    color: ${CSS_COLORS.FIELD_WARNING};
  }
  .disclaimer-component {
    html.rtl & {
      margin-right: -12px;
    }
  }
  .pay-now-button-container {
    margin-top: 10px;
  }
  .pay-now-button {
    width: 100%;
  }
  .general-error-message {
    margin-top: 10px;
    color: ${CSS_COLORS.FIELD_WARNING};
  }
`;

const RegisterGroupStage2 = connect(
    (state) => ({
        state: state,
        languageKey: state.languageKey, // make everything re-render
        isPaid: lodashGet(state, 'registerGroupStagesData.isPaid') || false,
        status: lodashGet(state, 'registerGroupStagesData.status'),
        groupKey: lodashGet(state, 'registerGroupStagesData.key'),
        createDate: lodashGet(state, 'registerGroupStagesData.createDate'),
        stage1Data: lodashGet(state, 'registerGroupStagesData.stage1Data'),
        linkedApplicationsData: lodashGet(state, 'registerGroupStagesData.stage2Data.linkedIndividualApplications') || [],
        refreshGroupData: lodashGet(state, 'globalControls.refreshGroupData')
    }),
    {})(RegisterGroupStage2Component);

export default RegisterGroupStage2;
