import React, {useEffect, useState} from 'react';
import { useRoutes, navigate } from 'hookrouter';
import styled from 'styled-components';
import {connect, useDispatch} from 'react-redux';
import lodashGet from 'lodash/get';
import GlobalStyle from '../style/globalStyle';
import Header from './Header';
import TopMenu from './TopMenu';
import MainPageAlerts from './MainPageAlerts';
import {getContextRoot, getLanguageValue, navigateToPath, scrollToElement} from "../common/functions";
import HeaderWithLogoBackground from "./HeaderWithLogoBackground";
import MainFooter from "./MainFooter";
import StepsBar from './StepsBar';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import RegisterStage1Component from './registerIndividualStages/RegisterStage1';
import RegisterStage2Component from './registerIndividualStages/RegisterStage2';
import RegisterStage3Component from './registerIndividualStages/RegisterStage3';
import RegisterStage4Component from './registerIndividualStages/RegisterStage4';
import RegisterStage5Component from './registerIndividualStages/RegisterStage5';
import RegisterStage6Component from './registerIndividualStages/RegisterStage6';
import RegisterStage7Component from './registerIndividualStages/RegisterStage7';
import RegisterStagePayComponent from './registerIndividualStages/RegisterStagePay';
import RegisterIndividualSuccess from './registerIndividualStages/RegisterIndividualSuccess';
import {ACTIONS} from '../redux/actions';
import {
    Switch,
    Route,
    useLocation,
    Prompt
} from "react-router-dom";
import CONSTANTS from "../common/constants";
import FloatingWhatsApp from "react-floating-whatsapp";


function RegisterIndividualPageBodyComponent(props) {
    const dispatch = useDispatch();
    const contextRoot = getContextRoot();
    const [ activeStage, setActiveStage ] = useState(1);
    const location = useLocation();
    const [ navigationWarningEnabled, setNavigationWarningEnabled ] = useState(false);

    useEffect(() => {
        setTimeout(() => { scrollToElement(window.screen.width > 960 ? '.scroll-top-positioning-desktop' : '.scroll-top-positioning-mobile'); }, 10);
    }, [ props.routerRedirect ]);

    useEffect(() => {
        let foundMatch = false;
        // make sure route have expected data
        const allowedPathsWithState = [ '/register-visa-for-individual/stage3', '/register-visa-for-individual/stage4', '/register-visa-for-individual/stage5', '/register-visa-for-individual/stage6', '/register-visa-for-individual/stage7', '/register-visa-for-individual/stagePay', '/register-visa-for-individual/stageSuccess', '/register-visa-for-individual/status', '/register-visa-for-individual/view' ];
        const allowedPathsWithoutState = [ '/register-visa-for-individual', '/register-visa-for-individual/stage2' ];
        const pathsWithoutWarning = [ '/register-visa-for-individual/view' ];
        for (let path of allowedPathsWithoutState) {
            if (location.pathname == path) {
                // just allow those paths
                foundMatch = true;
            }
        }
        let isInvalidDataFlag = false;
        for (let path of allowedPathsWithState) {
            if (location.pathname == path) {
                foundMatch = true;
                // validate previous state
                switch (location.pathname) {
                    case '/register-visa-for-individual/status':
                    case '/register-visa-for-individual/stageSuccess':
                    case '/register-visa-for-individual/stagePay':
                    case '/register-visa-for-individual/stage7':
                    case '/register-visa-for-individual/stage6':
                        // expect stage 5 questions page to be done
                        if (!lodashGet(props.registerIndividualStagesData, 'stage5Data.questionsAndAnswers')) {
                            isInvalidDataFlag = true;
                            break;
                        }
                    // case '/register-visa-for-individual/stage5':
                    //     // expect stage 4 data to be done
                    //     if (!lodashGet(props.registerIndividualStagesData, 'stage4Data.contactCity')) {
                    //         isInvalidDataFlag = true;
                    //         break;
                    //     }
                    case '/register-visa-for-individual/stage4':
                        // expect stage 3 data to be done
                        if (!lodashGet(props.registerIndividualStagesData, 'stage3Data.city')) {
                            isInvalidDataFlag = true;
                            break;
                        }
                    case '/register-visa-for-individual/stage3':
                    case '/register-visa-for-individual/view':
                        if (!props.individualKey) {
                            isInvalidDataFlag = true;
                        }
                }
                if (isInvalidDataFlag) {
                    navigateToPath(dispatch, getContextRoot());
                }
            }
        }
        // re-route to main page
        if (!foundMatch) {
            navigateToPath(dispatch, getContextRoot());
        }
        // activate navigation warning
        foundMatch = false;
        for (let path of pathsWithoutWarning) {
            if (location.pathname == path) {
                foundMatch = true;
            }
        }
        window.NAVIGATION_WARNING_ENABLED = !foundMatch;
        setNavigationWarningEnabled(!foundMatch);
        return () => {
            window.NAVIGATION_WARNING_ENABLED = false;
        };
    }, []);

    let progressBarItems = [];
    if (activeStage != false) {
        for (let loop = 1 ; loop <= 7 ; ++loop) {
            progressBarItems.push({ active: (loop < activeStage), text: getLanguageValue('register-individual.stages-' + loop), icon: <FiberManualRecordIcon className={"progress-icon" + (loop <= activeStage ? ' active' : '')} /> });
        }
    }

    return <>
        <GlobalStyle/>
        <RegisterIndividualPageBodyStyle>
            {CONSTANTS.WHATSAPP_ICON_ENABLED && <FloatingWhatsApp
                className={'floating-whatsapp'}
                phoneNumber={CONSTANTS.SUPPORT_PHONE_NUMBER}
                accountName={getLanguageValue('whatsapp.header-name')}
                statusMessage={getLanguageValue('whatsapp.status-message')}
                chatMessage={getLanguageValue('whatsapp.initial-message')}
            />}
            {navigationWarningEnabled && <Prompt when={true} message={getLanguageValue('register-common.warning-in-progress')} />}
            <Header className="header-component"/>
            <HeaderWithLogoBackground className="header-background-component" />
            <MainPageAlerts />
            <TopMenu className="top-menu-component" activeMenu={isStatusPath() ? 'check-visa-status' : 'register-visa'} disableAction={isStatusPath() ? 'retrieve-individual-application' : 'register-visa-for-individual'}/>
            <div className="content-body-container">
                <div className={"scroll-top-positioning-desktop"} />
                {activeStage != false && <div className={"steps-container"}>
                    <StepsBar items={progressBarItems} activeStage={activeStage} className={"steps-component"} itemPercentage={parseInt(100 / progressBarItems.length)} />
                </div>}
                <div className={"scroll-top-positioning-mobile"} />
                <div className="form-container">
                    <Switch>
                        <Route path="/register-visa-for-individual/stage2">
                            <RegisterStage2Component actions={{ setActiveStage, nextStage: () => { setActiveStage(3); navigateToPath(dispatch, getContextRoot() + 'register-visa-for-individual/stage3'); } } } />
                        </Route>
                        <Route path="/register-visa-for-individual/stage3">
                            <RegisterStage3Component actions={{ setActiveStage, nextStage: () => { setActiveStage(4); navigateToPath(dispatch, getContextRoot() + 'register-visa-for-individual/stage4'); } } } />
                        </Route>
                        <Route path="/register-visa-for-individual/stage4">
                            <RegisterStage4Component actions={{ setActiveStage, nextStage: () => { setActiveStage(5); navigateToPath(dispatch, getContextRoot() + 'register-visa-for-individual/stage5'); } } } />
                        </Route>
                        <Route path="/register-visa-for-individual/stage5">
                            <RegisterStage5Component actions={{ setActiveStage, nextStage: () => { setActiveStage(6); navigateToPath(dispatch, getContextRoot() + 'register-visa-for-individual/stage6'); } } } />
                        </Route>
                        <Route path="/register-visa-for-individual/stage6">
                            <RegisterStage6Component actions={{ setActiveStage, nextStage: () => { setActiveStage(7); navigateToPath(dispatch, getContextRoot() + 'register-visa-for-individual/stage7'); } } } />
                        </Route>
                        <Route path="/register-visa-for-individual/view">
                            <RegisterStage6Component actions={{ setActiveStage } } />
                        </Route>
                        <Route path="/register-visa-for-individual/stage7">
                            <RegisterStage7Component actions={{ setActiveStage, nextStage: () => { setActiveStage(8); navigateToPath(dispatch, getContextRoot() + 'register-visa-for-individual/stagePay'); } } } />
                        </Route>
                        <Route path="/register-visa-for-individual/status">
                            <RegisterStage7Component actions={{ setActiveStage } } />
                        </Route>
                        <Route path="/register-visa-for-individual/stagePay">
                            <RegisterStagePayComponent actions={{ setActiveStage } } />
                        </Route>
                        <Route path="/register-visa-for-individual/stageSuccess">
                            <RegisterIndividualSuccess actions={{ setActiveStage } } />
                        </Route>
                        <Route path="/register-visa-for-individual/">
                            <RegisterStage1Component actions={{ setActiveStage, nextStage: () => { setActiveStage(2); navigateToPath(dispatch, getContextRoot() + 'register-visa-for-individual/stage2'); } } } />
                        </Route>
                    </Switch>
                </div>
            </div>
            <MainFooter className="footer-component" />
        </RegisterIndividualPageBodyStyle>
    </>;

    function isStatusPath() {
        if (location.pathname == '/register-visa-for-individual/status') {
            return true;
        }
        return false;
    }
}

const RegisterIndividualPageBodyStyle = styled.div`
 .steps-container {
    padding: 25px;
    background-color: #eee;
 }
 .steps-component {
    .progress-icon {
      color: white;
      &.active {
        color: #1460aa;
      }
    }
    .progress-icon > path {
      fill: #aaa;
    }
    .progress-bar-text {
      color: black;
    }
 }
`;

const RegisterIndividualPageBody = connect(
    (state) => ({
        individualKey: lodashGet(state, 'registerIndividualStagesData.key'),
        registerIndividualStagesData: state.registerIndividualStagesData,
        routerRedirect: state.routerRedirect,
        languageKey: state.languageKey // make everything re-render
    }),
    {})(RegisterIndividualPageBodyComponent);

export default RegisterIndividualPageBody;