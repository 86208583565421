/* eslint-disable react-hooks/exhaustive-deps */

import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {connect, useDispatch} from 'react-redux';
import {
    changeFieldsWithPrefixAndCamelCase,
    getApiRoot,
    getContextRoot,
    getLanguageValue,
    googleRecaptcha,
    navigateToPath,
    notifyClientError
} from "../../common/functions";
import Grid from '@material-ui/core/Grid';
import lodashGet from 'lodash/get';
import RegisterBottomButtons from "./RegisterBottomButtons";
import CONSTANTS from '../../common/constants';
import { CSS_COLORS } from '../../common/cssColors';
import { ACTIONS } from '../../redux/actions';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';

function RegisterStage5Component(props) {
    const dispatch = useDispatch();
    const {
        actions
    } = props;

    const [ fieldsData, setFieldsData ] = useState({
        questionsAndAnswers: getQuestionsList(props.stage5Data),
        waiverOfRightsCertification: false,
        waiverOfRightsThirdParties: false
    });
    const [ loaders, setLoaders ] = useState({});
    let [ fieldErrors, setFieldErrors ] = useState({ });

    useEffect(() => {
        actions.setActiveStage(5);
        window.routeChangeTimestamp = new Date().getTime();
    }, []);

    return <RegisterStage5Style>
        <div className={"form-container"}>
            <h1>{getLanguageValue('register-individual.stage5-enter-personal-questions')}</h1>
            <div className="form-information-container">
                <span className="bold-text field-warning-color">{getLanguageValue('register-individual.stage5-required-fields-with-asterisk')}</span>
            </div>
            <h2>
                {getLanguageValue('register-individual.stage5-personal-questions')}
            </h2>
            <Grid item className={"form-fields-container contact-fields"} md={12}>
                {fieldsData.questionsAndAnswers.map((questionItem, questionItemIndex) => (
                    <Grid container key={questionItemIndex} className={"question-and-input-container question-key-" + questionItem.key + (fieldErrors[questionItem.key] ? ' error' : '')}>
                        <Grid item className={"question-container"} md={8}>
                            <div dangerouslySetInnerHTML={{ __html: questionItem.value }} />
                        </Grid>
                        <Grid item className={"input-container"} md={4}>
                            <RadioGroup className={"yes-or-no-question"} aria-label={getLanguageValue('register-individual.stage5-answer-yes-or-no')} name="yes-no" value={questionItem.answer} onChange={(e) => { answerQuestion(e, questionItem, questionItemIndex); } }>
                                <FormControlLabel classes={{ root: 'yes-label-control-root-component', label: 'yes-label-control-label-component' + (fieldErrors[questionItem.key] ? ' field-error' : '') }} className="yes-label-component" value="yes" control={<Radio classes={{ root: 'yes-root-component' + (fieldErrors[questionItem.key] ? ' error' : '') }} color="primary" />} label={getLanguageValue('register-individual.stage5-answer-yes')} />
                                <FormControlLabel classes={{ root: 'no-label-control-root-component', label: 'no-label-control-label-component' + (fieldErrors[questionItem.key] ? ' field-error' : '') }} className="no-label-component" value="no" control={<Radio classes={{ root: 'no-root-component' + (fieldErrors[questionItem.key] ? ' error' : '') }} color="primary" />} label={getLanguageValue('register-individual.stage5-answer-no')} />
                            </RadioGroup>
                        </Grid>
                    </Grid>
                ))}
            </Grid>
            <Grid className="waiver-of-rights-container">
                <h2>{getLanguageValue('register-individual.stage5-waiver-of-rights')}</h2>
                <div dangerouslySetInnerHTML={{ __html: getLanguageValue('register-individual.stage5-waiver-of-rights-disclaimer') }} />
                <div>
                    <h3>{getLanguageValue('register-individual.stage5-waiver-of-rights-certification')}</h3>
                    <FormControlLabel
                        control={
                            <Checkbox
                                className={"waiver-of-rights-certification" + (fieldErrors['waiverOfRightsCertification'] ? ' field-error' : '')}
                                checked={fieldsData.waiverOfRightsCertification}
                                onChange={(e) => { fieldsData.waiverOfRightsCertification = e.target.checked; setFieldsData({ ...fieldsData }); fieldErrors['waiverOfRightsCertification'] = false; setFieldErrors({ ...fieldErrors }); }}
                                name="waiver-of-rights-certification"
                                color="primary"
                            />
                        }
                        label={getLanguageValue('register-individual.stage5-waiver-of-rights-certification-text')}
                    />
                </div>
                <div>
                    <h3>{getLanguageValue('register-individual.stage5-waiver-of-rights-third-parties')}</h3>
                    <FormControlLabel
                        control={
                            <Checkbox
                                className={"waiver-of-rights-third-parties"}
                                checked={fieldsData.waiverOfRightsThirdParties}
                                onChange={(e) => { fieldsData.waiverOfRightsThirdParties = e.target.checked; setFieldsData({ ...fieldsData }); }}
                                name="waiver-of-rights-third-parties"
                                color="primary"
                            />
                        }
                        label={getLanguageValue('register-individual.stage5-waiver-of-rights-third-parties-text')}
                    />
                </div>
            </Grid>
            <RegisterBottomButtons
                actions={{
                    loaders,
                    isNextDisabled,
                    next,
                    back
                }}
            />
            {fieldErrors.submit ? <div className={"submit-error"}>{fieldErrors.submit}</div> : null}
        </div>
    </RegisterStage5Style>;

    function getQuestionsList(stage5Data) {
        let existingQuestionsAndAnswers = {};
        if (lodashGet(stage5Data, 'questionsAndAnswers')) {
            // save mapping of key / answer to set in next code
            for (let questionKey in stage5Data.questionsAndAnswers) {
                existingQuestionsAndAnswers[ questionKey ] = stage5Data.questionsAndAnswers[questionKey];
            }
        }

        const output = [];
        const questionsKeysArray = getLanguageValue('register-individual.stage5-questions-array').replace(/\s/g, '').split(',');
        for (let questionKeySuffix of questionsKeysArray) {
            const fullKey = 'register-individual.stage5-questions-item-' + questionKeySuffix;
            const questionValue = getLanguageValue(fullKey);
            if (questionValue) {
                output.push({ key: questionKeySuffix, value: questionValue, answer: existingQuestionsAndAnswers[questionKeySuffix] || '' });
            }
        }
        return output;
    }

    function answerQuestion(e, questionItem, questionItemIndex) {
        questionItem.answer = e.target.value;
        // remove error if have any
        fieldErrors[questionItem.key] = false;
        setFieldErrors({ ...fieldErrors });
        // update field
        setFieldsData({ ...fieldsData });
    }

    function isNextDisabled() {
        return false;
    }

    async function next() {
        // goto next stage
        if (loaders.next) {
            return;
        }
        let validateResult = validate({ setErrors: true });
        if (validateResult.length) {
            notifyClientError({ data: fieldsData, errors: fieldErrors });
            setTimeout(function() {
                if (document.querySelectorAll('.field-error')[0]) {
                    document.querySelectorAll('.field-error')[0].scrollIntoView();
                }
            }, 50);
            return;
        }
        if (actions && actions.nextStage) {
            try {
                setLoaders({ ...loaders, next: true });
                let duplicateData = { questionsAndAnswers: {} };
                for (let loop = 0 ; loop < fieldsData.questionsAndAnswers.length ; ++loop) {
                    const item = fieldsData.questionsAndAnswers[loop];
                    duplicateData.questionsAndAnswers[item.key] = item.answer;
                }
                // override field to be integer
                let url = getApiRoot() + 'update-process/?type=individual&languageKey=' + props.languageKey + '&td=' + (new Date().getTime() - window.routeChangeTimestamp);
                let recaptchaToken = await googleRecaptcha();
                let options = {
                    ...CONSTANTS.POST_DEFAULT_OPTIONS,
                    body: JSON.stringify({
                        recaptchaToken,
                        ...changeFieldsWithPrefixAndCamelCase(duplicateData, 'stage5'),
                        stage: "5",
                        key: props.individualKey
                    })
                };
                let result = await (await fetch(url, options)).json();
                if (!lodashGet(result, 'data.success')) {
                    setLoaders({ ...loaders, next: false });
                    setFieldErrors({ ...fieldErrors, submit: getLanguageValue('register-network-error') });
                    return;
                }
                let stateQuestionsAndAnswers = {};
                for (let item of fieldsData.questionsAndAnswers) {
                    stateQuestionsAndAnswers[item.key] = item.answer;
                }
                dispatch({
                    type: ACTIONS.GENERIC_SET_VALUE,
                    payload: [{
                        path: 'registerIndividualStagesData.stage5Data',
                        value: { questionsAndAnswers: stateQuestionsAndAnswers }
                    }]
                });
                actions.nextStage();
            }
            catch (err) {
                setFieldErrors({ ...fieldErrors, submit: getLanguageValue('register-network-error') });
            }
            setLoaders({ ...loaders, next: false });
        }
    }

    function validate(config) {
        let errors = [];
        if (lodashGet(config, 'setErrors')) {
            fieldErrors = {};
        }
        for (let loop = 0 ; loop < fieldsData.questionsAndAnswers.length ; ++loop) {
            let item = fieldsData.questionsAndAnswers[loop];
            if (['yes','no'].indexOf(item.answer) === -1) {
                errors.push({ error: 'no answer on index ' + loop });
                if (lodashGet(config, 'setErrors')) {
                    fieldErrors[item.key] = true;
                }
            }
        }
        if (!fieldsData.waiverOfRightsCertification) {
            errors.push({ error: 'waiverOfRightsCertification' });
            if (lodashGet(config, 'setErrors')) {
                fieldErrors['waiverOfRightsCertification'] = true;
            }
        }
        if (lodashGet(config, 'setErrors')) {
            setFieldErrors(fieldErrors);
        }
        return errors;
    }

    function back() {
        setTimeout(() => {
            navigateToPath(dispatch, getContextRoot() + 'register-visa-for-individual/stage4');
        }, 10);
    }
}

const RegisterStage5Style = styled.div`
  padding-top: 20px;
  padding-bottom: 20px;
  .form-container {
    width: 70%;
    margin: 0 auto;
    padding-bottom: 25px;
  }
  .question-and-input-container {
    border-bottom: 1px solid black;
    &.error {
      border-bottom: 1px solid ${CSS_COLORS.FIELD_WARNING};
    }
  }
  .question-container, .input-container {
    width: 100%;
  }
  .question-container {
    @media (max-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE}px) {
      margin-bottom: 20px;
    }
  }
  .input-container {
    html.rtl & {
      direction: ltr;
      @media (max-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE}px) {
        direction: rtl;
      }
    }
  }
  .yes-or-no-question {
    flex-direction: row;
    @media (max-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE}px) {
      margin-right: -25px;
    }
  }
  .yes-root-component, .no-root-component, .no-label-control-label-component, .yes-label-control-label-component {
   &.error, &.field-error {
    color: ${CSS_COLORS.FIELD_WARNING};
   }
  }
  .waiver-of-rights-certification {
    &.field-error {
      color: ${CSS_COLORS.FIELD_WARNING};
    }
  }
  .submit-error {
    margin-top: 10px;
    color: ${CSS_COLORS.FIELD_WARNING};
  }
`;

const RegisterStage5 = connect(
    (state) => ({
        languageKey: state.languageKey, // make everything re-render
        registerIndividualStagesData: state.registerIndividualStagesData,
        individualKey: lodashGet(state, 'registerIndividualStagesData.key'),
        stage5Data: lodashGet(state, 'registerIndividualStagesData.stage5Data')
    }),
    {})(RegisterStage5Component);

export default RegisterStage5;