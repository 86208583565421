import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { connect, useDispatch } from 'react-redux';
import Button from '@material-ui/core/Button';
import HomeIcon from '@material-ui/icons/Home';
import CreateIcon from '@material-ui/icons/Create';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import SearchIcon from '@material-ui/icons/Search';
import HelpIcon from '@material-ui/icons/Help';
import MenuIcon from '@material-ui/icons/Menu';
import Collapse from '@material-ui/core/Collapse';
import LoopIcon from '@material-ui/icons/Loop';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {
    getContextRoot, getLanguageValue, navigateToPath, resetRegisterIndividualStagesData,
    resetRegisterGroupStagesData, resetRetrieveIndividualData, resetRetrieveGroupData
} from "../common/functions";
import CONSTANTS from '../common/constants';
import MenuDropDown from './MenuDropDown';
import { ACTIONS } from "../redux/actions";
import { useLocation } from 'react-router-dom';
import WarningBeforeStartingVisaComponent from './WarningBeforeStartingVisa';

function TopMenuComponent(props) {
    const isMobile = useMediaQuery(`(max-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE}px)`);
    const [ isMobileMenuOpen, setIsMobileMenuOpen ] = useState(false);
    const location = useLocation();

    const dispatch = useDispatch();

    useEffect(() => {}, [isMobile]);

    const contentOptions = <>
        <Button className={"menu-title" + (props.activeMenu === 'homepage' ? ' active' : '')} onClick={() => {
            if (['register-visa-for-individual', 'register-visa-for-group'].indexOf(props.disableAction) > -1) {
                let confirmResult = window.confirm(getLanguageValue('register-common.warning-in-progress'));
                if (!confirmResult) {
                    return;
                }
            }
            if (props.disableAction === 'homepage') { window.location.reload(); return; }
            window.location.href = (getContextRoot() || '/');
        }}><HomeIcon className="menu-icon" />{getLanguageValue('top-menu.homepage')}</Button>
        <MenuDropDown
            toggleOnMouseEvents={true}
            containerClassName={"top-menu-component"}
            buttonClassName={"menu-title" + (props.activeMenu === 'register-visa' ? ' active' : '')}
            menuItemClassName={"menu-title-submenu"}
            title={<><CreateIcon className="menu-icon" />{getLanguageValue('top-menu.apply-for-visa')}<ArrowDropDownIcon className={"menu-icon"} /></>}
            options={[
                {
                    display: getLanguageValue('top-menu.apply-for-visa.individual'),
                    action: function() {
                        if (['register-visa-for-group'].indexOf(props.disableAction) > -1 || (props.disableAction == 'register-visa-for-individual' && location.pathname != '/register-visa-for-individual')) {
                            let confirmResult = window.confirm(getLanguageValue('register-common.warning-in-progress'));
                            if (!confirmResult) {
                                return;
                            }
                        }
                        if (props.disableAction === 'register-visa-for-individual' && location.pathname == '/register-visa-for-individual') { return; }
                        if (CONSTANTS.SYSTEM_DISABLED == 'yes') {
                            dispatch({
                                type: ACTIONS.GENERIC_SET_VALUE,
                                payload: [{
                                    path: 'globalControls.showNoNewVisasPopup',
                                    value: true
                                }]
                            });
                            return;
                        }
                        resetRegisterIndividualStagesData(dispatch, props.state);
                        dispatch({
                            type: ACTIONS.GENERIC_SET_VALUE,
                            payload: [
                                {
                                    path: 'globalControls.openWarningForIndividual',
                                    value: true
                                },
                                {
                                    path: 'warningForIndividualConfig',
                                    value: {
                                        title: getLanguageValue('register-application-warning-before-starting-individual-visa-title'),
                                        content: getLanguageValue('register-application-warning-before-starting-individual-visa-content')
                                    }
                                }
                            ]
                        });
                    }
                },
                {
                    display: getLanguageValue('top-menu.apply-for-visa.group'),
                    action: function() {
                        if (['register-visa-for-individual'].indexOf(props.disableAction) > -1 || (props.disableAction == 'register-visa-for-group' && location.pathname != '/register-visa-for-group')) {
                            let confirmResult = window.confirm(getLanguageValue('register-common.warning-in-progress'));
                            if (!confirmResult) {
                                return;
                            }
                        }
                        if (CONSTANTS.SYSTEM_DISABLED == 'yes') {
                            dispatch({
                                type: ACTIONS.GENERIC_SET_VALUE,
                                payload: [{
                                    path: 'globalControls.showNoNewVisasPopup',
                                    value: true
                                }]
                            });
                            return;
                        }
                        if (props.disableAction === 'register-visa-for-group' && location.pathname == '/register-visa-for-group') { return; }
                        resetRegisterGroupStagesData(dispatch, props.state);
                        setTimeout(() => {
                            navigateToPath(dispatch, getContextRoot() + 'register-visa-for-group');
                        }, 10);
                    }
                }
            ]} />
        <MenuDropDown
            toggleOnMouseEvents={true}
            containerClassName={"top-menu-component check-visa-status"}
            buttonClassName={"menu-title" + (props.activeMenu === 'check-visa-status' ? ' active' : '')}
            menuItemClassName={"menu-title-submenu"}
            title={<><SearchIcon className="menu-icon" />{getLanguageValue('top-menu.check-visa-status')}<ArrowDropDownIcon className={"menu-icon"} /></>}
            options={[
                {
                    display: getLanguageValue('top-menu.check-visa-status.individual'),
                    action: function() {
                        if (['register-visa-for-individual', 'register-visa-for-group'].indexOf(props.disableAction) > -1) {
                            let confirmResult = window.confirm(getLanguageValue('register-common.warning-in-progress'));
                            if (!confirmResult) {
                                return;
                            }
                        }
                        if (props.disableAction === 'retrieve-individual-application') { return; }
                        if (CONSTANTS.SYSTEM_DISABLED == 'yes') {
                            dispatch({
                                type: ACTIONS.GENERIC_SET_VALUE,
                                payload: [{
                                    path: 'globalControls.showNoNewVisasPopup',
                                    value: true
                                }]
                            });
                            return;
                        }
                        resetRetrieveIndividualData(dispatch, props.state);
                        setTimeout(() => {
                            navigateToPath(dispatch, getContextRoot() + 'retrieve-individual-application');
                        }, 10);
                    }
                },
                {
                    display: getLanguageValue('top-menu.check-visa-status.group'),
                    action: function() {
                        if (['register-visa-for-individual', 'register-visa-for-group'].indexOf(props.disableAction) > -1) {
                            let confirmResult = window.confirm(getLanguageValue('register-common.warning-in-progress'));
                            if (!confirmResult) {
                                return;
                            }
                        }
                        if (props.disableAction === 'retrieve-group-application') { return; }
                        if (CONSTANTS.SYSTEM_DISABLED == 'yes') {
                            dispatch({
                                type: ACTIONS.GENERIC_SET_VALUE,
                                payload: [{
                                    path: 'globalControls.showNoNewVisasPopup',
                                    value: true
                                }]
                            });
                            return;
                        }
                        resetRetrieveGroupData(dispatch, props.state);
                        setTimeout(() => {
                            navigateToPath(dispatch, getContextRoot() + 'retrieve-group-application');
                        }, 10);
                    }
                }
            ]} />
            {
            isMobile && <MenuDropDown
                toggleOnMouseEvents={true}
                containerClassName={"top-menu-component"}
                buttonClassName={"menu-title" + (props.activeMenu === 'continue-visa-process' ? ' active' : '')}
                menuItemClassName={"menu-title-submenu"}
                title={<><LoopIcon className="menu-icon" />{getLanguageValue('top-menu.continue-visa-process')}<ArrowDropDownIcon className={"menu-icon"} /></>}
                options={[
                    {
                        display: getLanguageValue('top-menu.continue-visa-process.individual'),
                        action: function() {
                            if (['register-visa-for-individual', 'register-visa-for-group'].indexOf(props.disableAction) > -1) {
                                let confirmResult = window.confirm(getLanguageValue('register-common.warning-in-progress'));
                                if (!confirmResult) {
                                    return;
                                }
                            }
                            if (props.disableAction === 'retrieve-individual-application-continue-process') { return; }
                            resetRetrieveIndividualData(dispatch, props.state);
                            setTimeout(() => {
                                navigateToPath(dispatch, getContextRoot() + 'retrieve-individual-application/continue-process');
                            }, 10);
                        }
                    },
                    {
                        display: getLanguageValue('top-menu.continue-visa-process.group'),
                        action: function() {
                            if (['register-visa-for-individual', 'register-visa-for-group'].indexOf(props.disableAction) > -1) {
                                let confirmResult = window.confirm(getLanguageValue('register-common.warning-in-progress'));
                                if (!confirmResult) {
                                    return;
                                }
                            }
                            if (props.disableAction === 'retrieve-group-application-continue-process') { return; }
                            resetRetrieveGroupData(dispatch, props.state);
                            setTimeout(() => {
                                navigateToPath(dispatch, getContextRoot() + 'retrieve-group-application/continue-process');
                            }, 10);
                        }
                    }
                ]} />
            }
        {shouldShowHelpMenu() && <Button className={"menu-title"} onClick={() => {
            try {
                document.getElementById('need-help-component').scrollIntoView();
            }
            catch (err) {
                // nothing to do
            }
        }}><HelpIcon className="menu-icon" />{getLanguageValue('top-menu.help')}</Button>}
    </>;



    return <TopMenuStyle>
        { isMobile ?
            <div className="mobile-container">
                <div className="mobile-header" onClick={() => { setIsMobileMenuOpen(!isMobileMenuOpen); } }>
                    <span className="mobile-title">{getLanguageValue('top-menu.mobile-title')}</span> <span className="mobile-icon"><MenuIcon /></span>
                </div>
                <Collapse className="mobile-menu-options" in={isMobileMenuOpen}>
                    {contentOptions}
                </Collapse>
            </div>
            :
            contentOptions
        }
        {props.warningForIndividualConfig.content && <WarningBeforeStartingVisaComponent />}
    </TopMenuStyle>;

    function shouldShowHelpMenu() {
        if (location.pathname == '/') {
            return true;
        }
        return false;
    }

}

const TopMenuStyle = styled.div`
    // make content non-selectable
    -webkit-user-select: none; /* Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */

    background-color: #1c3764;
    color: white;
    text-align: center;
    .menu-icon {
        vertical-align: middle;
        html.rtl & {
          padding-left: 5px;
        }
        html.ltr & {
          padding-right: 5px;
        }
    }
    .menu-title {
        display: inline-block;
        width: 160px;
        html.ltr & {
          width: 195px;
          @media (max-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE}px) {
            width: 320px;
          }
        }
        text-align: center;
        color: white;
        &:disabled {
          color: white;
          background: #045575;
        }
        &.active {
          color: white;
          background: #045575;
        }
        html.rtl & {
          text-align: right;
        }
        
        @media (max-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE}px) {
          display: block;
          height: auto;
          width: 100%;
          text-align: left;
        }
    }
    .top-menu-component {
        display: inline-block;
        @media (max-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE}px) {
          display: block;
          height: auto;
        }
        &.check-visa-status .popper-component {
          min-width: 240px;
        }
        .popper-component .menu-title-submenu > li {
          font-size: 16px;
        }
    }
    
    // mobile
    .mobile-header {
      position: relative;
      height: 40px;
      vertical-align: middle;
    }
    .mobile-icon {
      position: absolute;
      right: 15px;
      > svg {
        vertical-align: middle;
      }
    }
    .mobile-menu-options {
      border-top: 1px solid white;
    }
    .mobile-menu-options .menu-title {
      padding-left: 15px;
    }
    .mobile-menu-options .menu-title ul {
      padding-left: 15px;
      background-color: #1c3764;
    }
    
`;

const TopMenu = connect(
    (state) => ({
        state: state,
        warningForIndividualConfig: state.warningForIndividualConfig,
    }),
    {})(TopMenuComponent);

export default TopMenu;